import { faDownload, faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from '@ufx-ui/core'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import usePrevious from '../../hooks/usePrevious'
import useWindowSize from '../../hooks/useWindowSize'
import Backward from '../../images/icons/backward.svg'
import Forward from '../../images/icons/forward.svg'
import { formatTime } from '../../utils'
import ProgressBar from '../core/ProgressBar'

const initialProgress = {
  loaded: 0,
  loadedSeconds: 0,
  played: 0,
  playedSeconds: 0,
}

const hiddenStyle = { display: 'none' }

const speedList = [
  { text: '1.0x', val: 1 },
  { text: '1.25x', val: 1.25 },
  { text: '1.5x', val: 1.5 },
  { text: '2.0x', val: 2 },
]

export default function AudioPlayer({
  src,
  disabled = false,
  paidPercentage = 0,
  onEnded = () => {},
  showControls = true,
  downloadable = false,
  showPlayLabel = false,
  setDurationCallback = () => {},
  showOnlyHiddenPlayer,
}) {
  const audioRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [progress, setProgress] = useState(initialProgress)
  const [prevPlayedPercentage, setPrevPlayedPercentage] = useState(
    initialProgress.played
  )
  const [askForFund, setAskForFund] = useState(false)
  const [playbackSpeed, setPlaybackSpeed] = useState(
    _get(speedList, ['0', 'val'])
  )
  const currentSpeedIndex = _find(
    speedList,
    ({ val: v }) => v === playbackSpeed
  )

  const prevPaidPercentage = usePrevious(paidPercentage)
  const { isSM: isMobile } = useWindowSize()

  const onSetDuration = value => {
    setDuration(value)
    setDurationCallback(value)
  }

  const stopPlaying = (askForFundTemp = true, restart = false) => {
    setIsPlaying(false)
    setAskForFund(askForFundTemp)
    if (restart) {
      setProgress(initialProgress)
    }
    onEnded(askForFundTemp)
  }

  // toggle play
  const handlePlay = e => {
    e.stopPropagation()
    if (disabled) {
      stopPlaying(true)
    } else {
      setIsPlaying(!isPlaying)
    }
  }

  // when audio stops, ask for fund if not fully played (means it needs more fund)
  const onStopped = () => {
    // e.stopPropagation()

    if (progress.played < 0.99) {
      stopPlaying(true)
    } else {
      stopPlaying(false)
    }
  }

  // seek to percentage of the audio
  const seek = (p, startPlay = false) => {
    const seekTo = _get(audioRef, 'current.seekTo', null)

    if (seekTo) {
      setProgress({
        ...progress,
        played: p,
        playedSeconds: duration * p,
      })
      seekTo(duration * p)

      if (startPlay) {
        setIsPlaying(true)
      }
    }
  }

  // click on the progress bar to seek audio
  const onSeek = percentage => {
    if (disabled) {
      stopPlaying(true)
      return
    }

    const seekTo = _get(audioRef, 'current.seekTo', null)
    if (seekTo) {
      if (percentage * 100 > paidPercentage) {
        stopPlaying(true)
      } else {
        seek(percentage, true)
      }
    }
  }

  // When ready to play, check whether it's refreshing src, if so, seek to previous played position
  const onReady = () => {
    if (prevPlayedPercentage > 0) {
      seek(prevPlayedPercentage)
      setPrevPlayedPercentage(initialProgress.played)
    }
  }

  const onChangeSpeed = e => {
    const value = _get(e, 'target.value')
    setPlaybackSpeed(Number(value))
  }

  useEffect(() => {
    // if paidPercentage is changed, store the current played position
    if (prevPaidPercentage !== paidPercentage && progress.played > 0) {
      setPrevPlayedPercentage(progress.played)
    }
  }, [paidPercentage, prevPaidPercentage, progress.played])

  // not ask for fund if it's playing
  useEffect(() => {
    if (isPlaying) {
      setAskForFund(false)
    }
  }, [isPlaying])

  const perc = (progress.playedSeconds / duration) * 100

  const seekToTime = time => {
    const seekTo = _get(audioRef, 'current.seekTo', null)

    if (seekTo) {
      seekTo(time)
      setProgress({
        ...progress,
        played: time / duration,
        playedSeconds: time,
      })
    }
  }

  const setBackward = e => {
    e.stopPropagation()

    const value = progress.playedSeconds - 30
    if (value > 0) {
      seekToTime(value)
    } else {
      seekToTime(0)
    }
  }

  const setForward = e => {
    e.stopPropagation()

    const value = progress.playedSeconds + 30
    if (value < duration) {
      seekToTime(value)
    } else {
      seekToTime(duration)
      stopPlaying(false)
    }
  }

  const hiddenPlayer = (
    <ReactPlayer
      ref={audioRef}
      style={hiddenStyle}
      onProgress={val => setProgress(val)}
      onDuration={onSetDuration}
      onEnded={onStopped}
      onReady={onReady}
      url={`${src}?p=${paidPercentage}`}
      height={60}
      width='100%'
      controls
      playing={isPlaying}
      playbackRate={playbackSpeed}
    />
  )

  const player = (
    <>
      <div className='play-btn-wrapper'>
        <Button intent='secondary' className='play-btn' onClick={handlePlay}>
          {isPlaying ? (
            <FontAwesomeIcon icon={faPause} />
          ) : (
            <FontAwesomeIcon icon={faPlay} />
          )}
          {showPlayLabel && <span>Listen</span>}
        </Button>
      </div>
      {hiddenPlayer}
    </>
  )

  if (showOnlyHiddenPlayer) {
    return hiddenPlayer
  }

  if (!showControls) {
    return player
  }

  const playerSpeedControl = (
    <Tooltip
      content={
        <>
          {_map(speedList, ({ text, val }) => (
            <Button
              key={val}
              minimal
              className='speed speed-item'
              onClick={onChangeSpeed}
              value={val}
            >
              {text}
            </Button>
          ))}
        </>
      }
      className='speed-popover'
      placement='bottom'
      trigger='click'
    >
      <div className='speed speed-text'>{_get(currentSpeedIndex, 'text')}</div>
    </Tooltip>
  )

  const downloadBtn = downloadable && (
    <a href={src} target='_blank' rel='noreferrer'>
      <FontAwesomeIcon icon={faDownload} />
    </a>
  )

  const forwardBackwardBtns = (
    <>
      <Button className='backward-btn ' minimal onClick={setBackward}>
        <img src={Backward} alt='backward-icon' />
        <span className='small'>30</span>
      </Button>
      <Button className='forward-btn' minimal onClick={setForward}>
        <img src={Forward} alt='forward-icon' />
        <span className='small'>30</span>
      </Button>
    </>
  )

  return (
    <div className='player'>
      {player}

      {showControls && (
        <>
          <ProgressBar
            textStart={
              askForFund ? (
                <span className='player__tips'>More funding needed!</span>
              ) : disabled ? (
                '-:-:-'
              ) : (
                formatTime(progress.playedSeconds)
              )
            }
            textEnd={formatTime(duration)}
            // color='#b200de'
            color='linear-gradient(180deg, #B200DE 0%, #860089 100%), linear-gradient(180deg, #B200DE 0%, #860089 100%);'
            color2='#85009b'
            // to start progress indicator early show -10 when perc = 0
            perc={perc === 0 ? -10 : perc}
            onClick={onSeek}
            role='presentation'
            isMobile={isMobile}
          />

          {!isMobile ? (
            <div className='player__controls'>
              <div className='fw-bw-wrapper'>{forwardBackwardBtns}</div>

              {playerSpeedControl}
              {downloadBtn}
            </div>
          ) : (
            <>
              {forwardBackwardBtns}
              <div className='row-3'>
                {playerSpeedControl}
                {downloadBtn}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
