import qr from 'crypto-payment-url/qrcode'
import React from 'react'

export default function QrCode({
  lightning = '',
  bitcoin = '',
  defaultSrc = '',
  blocked,
}) {
  const invoice =
    lightning && !bitcoin
      ? qr.lightning({
          lnurl: lightning,
        })
      : qr.bitcoin({
          lightning,
          address: bitcoin,
        })

  return invoice.qrcode && !blocked ? (
    <a href={invoice.url} target='_blank' className='qrcode' rel='noreferrer'>
      {/* eslint-disable-next-line react/no-danger */}
      <div
        dangerouslySetInnerHTML={{
          __html: invoice.qrcode,
        }}
      />
    </a>
  ) : (
    <img src={defaultSrc} alt='' />
  )
}
