import _isEmpty from 'lodash/isEmpty'
import { useState, useCallback, useEffect } from 'react'

import { apiURL } from '../config'
import useInterval from './useInterval'

const TIME_INTERVAL_MS = 2 * 1000 // 20 sec
export const LAST_PRICE = 6

const useTicker = (symbol = 'tBTCUSD') => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)

  const fetchData = useCallback(async () => {
    try {
      const res = await fetch(apiURL('/price/v2/ticker/' + symbol))
      const json = await res.json()
      setResponse(json)
    } catch (err) {
      setError(err)
    }
  }, [symbol])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useInterval(() => {
    fetchData()
  }, TIME_INTERVAL_MS)

  return !_isEmpty(error) ? {} : response
}

export default useTicker
