import _get from 'lodash/get'
import React from 'react'

function CharityText({ charity }) {
  return (
    <p className='highlight-primary'>
      50% of this goal will be donated to{' '}
      <a
        href={_get(charity, 'href')}
        target='_blank'
        rel='noreferrer'
        className='donated'
      >
        {_get(charity, 'title')}
      </a>
    </p>
  )
}

export default CharityText
