import cx from 'classnames'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import _isNumber from 'lodash/isNumber'
import Slider from 'rc-slider'
import React, { useRef } from 'react'

const ProgressBar = ({
  textStart,
  textEnd,
  perc,
  color = '#2e7b00',
  color2 = '#245f00',
  onClick,
  className,
  isMobile,
}) => {
  const ref = useRef(null)

  const transformedPerc = perc > 0 ? Math.max(perc, 1) : 0

  const onProgressChange = e => {
    let percentage
    const bar = _get(ref, 'current', null)

    if (_isNumber(e)) {
      percentage = e / 100
    } else if (bar) {
      e.stopPropagation()
      const r = bar.getBoundingClientRect()
      percentage = (e.clientX - r.left) / (r.right - r.left)
    }

    onClick(percentage)
  }

  const buttonProps = _isFunction(onClick)
    ? {
        onClick: onProgressChange,
        onKeyPress: onProgressChange,
        role: 'button',
        tabIndex: '0',
      }
    : {}

  if (isMobile) {
    return (
      <div className='progress__mobile'>
        <Slider
          step={0.01}
          min={0}
          max={100}
          className='progress-slider'
          onChange={onProgressChange}
          value={perc}
        />
        <div className='timing'>
          <span>{textStart}</span>
          <span>{textEnd}</span>
        </div>
      </div>
    )
  }

  return (
    <div
      ref={ref}
      className={cx('progress', className)}
      style={{
        '--c2': color2,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
    >
      <div
        className={cx('progress-bar', { skew: transformedPerc < 100 })}
        style={{
          '--c': color,
          '--p': transformedPerc,
        }}
        // style={{ width: '60%' }}
      >
        {/* <div className='timing'>
          <span>{textStart}</span>
          <span>{textEnd}</span>
        </div> */}
      </div>
      <div className='timing'>
        <span>{textStart}</span>
        <span>{textEnd}</span>
      </div>
    </div>
  )
}

export default ProgressBar
